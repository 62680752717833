@value breakpoint-medium from '../../../../../components/styles/mediaQueries.css';

.inputGroupHeader {
  display: flex;
  justify-content: space-between;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 200px;
}


.addedItems {
  margin-top: 2rem;
  padding-bottom: 2rem;
  max-width: 700px;
  min-width: 400px;
  width: fit-content;
}

.addPaddingTop {
  padding-top: 3rem;
}

.inputBox {
  width: 200px;
}

.copies {
  width: 100px;
}

.itemDetailsContainer {
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-width: 200px;
  width: fit-content;
}

.formContainer {
  float: left;
  padding-right: 1rem;
  width: 200px;
}

.addItemButton {
  display: flex;
  flex-direction: column;
  width: 155px;
  align-self: end;
}

.addOrderItemHeader {
  margin-bottom: 0.75em;
}

.mainTable {
  padding: 0;
}

.header {
  font-family: "Proxima N W01 Smbd", "Proxima Nova Semibold", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.data, .header {
  padding: 2rem;
  text-align: left;
}

.sku {
  width: fit-content;
}

.row:not(:last-child) .data {
  border-bottom: 1px solid #dfdfdf;
}

@media breakpoint-medium {
  .relatedContainer {
    flex-direction: column;
  }

  .formContainer {
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 1000px) {
  .data, .header {
    padding: 1.6rem;
  }
}
