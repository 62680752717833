@value tablet-min-size from '../styles/mediaQueries.css';
@value mobile-max-width from '../styles/mediaQueries.css';

/* Mobile collapsed */

.link {
  display: block;
  margin: auto;
  padding: 0.9rem 2.1rem;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.panel {
  position: relative;
  z-index: 3000;
}

.panel > * {
  white-space: nowrap;
}

.sandboxBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.6rem;
  background-color: var(--color-blue-20);
  color: var(--color-blue-100);
  text-align: center;
}

.header {
  justify-content: space-between;
  align-items: center;
  height: 5.6rem;
  background-color: var(--color-white);
  z-index: 3000;
}

.headerAction {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  border: 0;
  background-color: transparent;
  float: right;
  text-align: center;
  margin-right: 2rem;
  margin-top: 2rem;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 1rem;
}

.headerAction:hover {
  cursor: pointer;
  outline: none;
}

.logoFull {
  display: none;
  padding: 0.8rem;
}

.list {
  flex: 1;
  display: none;
  padding: 1.6rem 2.4rem;
  background-color: var(--color-white);
  margin-top: 5rem;
}

.list > * + * {
  margin-top: 0.8rem;
  font-size: 18px;
}

.listItem {
  position: relative;
  display: flex;
  align-items: center;
  height: 4rem;
  color: var(--color-cool-grey-50);
  text-decoration: none;
  white-space: nowrap;
  overflow-x: visible;
  font-size: 16px;
}

.listItemHighlighted {
  color: var(--color-alert-pink);
}

.listItemHighlighted:hover {
  color: var(--color-alert-pink);
}

.listItemIcon {  
  flex-shrink: 0;
  text-align: center;
  margin-right: 1.6rem;
  transition: margin-right 0.4s linear;  
}

.inset .listItemIcon {
  display: none;
}

.divider {
  height: 3.2rem;
  opacity: 0;
  transition: opacity 0.4s linear;
  flex: 1;
}

.subDivider {
  flex: 0.2;
  opacity: 0;
  transition: opacity 0.4s linear;
}

.content {
  flex: 1;
  background-color: var(--greyscale-lightest);
}

.buttonContainer {
  display: none;
}

.selected {
  position: relative;
  color: var(--color-black);
  fill: black !important;
}

.selected::after {
  content: '';
  position: absolute;
  top: 0rem;
  left: -3.17rem;
  bottom: 0.8rem;
  width: 4px;
  height: 40px;
  background-color: var(--color-teal-10);
}

.tooltip {
  display: none;
}

/* Mobile expanded */

@media screen and (max-width: mobile-max-width) {
  .panelMobileExpanded {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: radial-gradient(163.41% 64.04% at 111.2% 96.31%, #6C63FF 0%, #4630D4 100%);
  }

  .panelMobileExpanded .header {
    flex-shrink: 0;
  }

  .panelMobileExpanded .list {
    display: flex;
    flex-direction: column;
  }

  .contentHidden {
    display: none;
  }
  
  .selected::after {
    top: 0rem;
    left: -2.4rem;
  }

  .header {
    background-color: transparent;
  }

  .list {
    background-color: transparent;
  }

  .listItem {
    color: var(--color-grey-30);
  }
 
  .selected {
    position: relative;
    color: var(--color-white); 
  }
  
  .listItem:hover {
    cursor: pointer;
  }

  .listItemHighlighted {
    color: var(--color-alert-pink);
  }

  .listItemHighlighted:hover {
    color: var(--color-alert-pink);
  }

  .closeAction {
    color: var(--color-white);
  }

  .menuCloseIcon {
    width: 12px;
    height: 12px;
    padding-left: 1em;
    padding-top: 0.2rem;
  }

  .navTextContainer {
    display: flex;
    justify-content: left;
    padding-top: 2.75em;
    padding-bottom: 3em;
    padding-left: 2.5em;
  }

  .navText {
    font-size: 16px;
    line-height: 19px;
    height: 46px;
    width: 270px;
    text-align: left;
    letter-spacing: 0.441176px;
    color: var(--color-white);
    white-space: normal;
  }

  .drawing {
    bottom: 3.25em;
    position: absolute;
    right: 0px;
  }
}

@media screen and (min-width: tablet-min-size) {
  /* tablet */
  
  .listItem {
    color: var(--color-cool-grey-50);
  }

  .itemContent:hover {
    background-color: var(--color-grey-5);
  }

  .listItem:hover::before {	
    display: none;	
  }	
   
  .listItemTitle {	
    display: none;	
  }	

  .selected {
    position: relative;
    color: var(--color-black);
  }

  .listItemHighlighted {
    color: var(--color-alert-pink);
  }
  
  .listItemHighlighted:hover {
    color: var(--color-alert-pink);
  }

  .header {
    background-color: var(--color-white);   
    box-shadow: var(--shadow-small);
    box-sizing: border-box;
  }

  .list {
    background-color: var(--color-white);
  }

  .link {
    padding: 0.8rem;
  }

  .listItemIcon {  
    flex-shrink: 0;
    text-align: center;
    transition: margin-right 0.4s linear;        
    display: block;
    margin: auto;	
  }

  .selected::after {
    content: '';
    position: absolute;
    top: 0rem;
    left: -0.9rem;
    bottom: 0.8rem;
    width: 4px;
    height: 40px;
    background-color: var(--color-blue-50);
  }

  .panel {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    background-color: var(--color-white);
    box-shadow: none;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    transition: width 0.4s ease-in-out, padding 0.4s ease-in-out;
    align-items: center;
    width: 5.6rem;	
    padding: 1.25em 0.8rem 0;
  }

  .panelSandbox {
    top: 4rem;
  }

  .sandboxBanner {
    position: sticky;
    top: 0;
    z-index: 3001;
    padding: 0;
    height: 4rem;
  }

  .header {
    justify-content: flex-start;
    background-color: transparent;
    box-shadow: none;
    height: auto;
    padding: 0;
  }

  .headerAction {
    display: none;
  }

  .logoFull {	
    display: none;	
  }	

  .logoCompact {	
    display: block;	
  }	

  .list {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    transition: margin-top 0.4s ease-in-out, padding 0.4s ease-in-out;
    margin-top: 8rem;	
    padding: 0 0 3.2rem;
    width: 2.5em;
  }

  .content {
    margin-left: 3.75em;
    box-sizing: border-box;
    transition: margin-left 0.4s ease-in-out;
    background-color: var(--greyscale-lightest);
  }

  .contentFullScreen {
    margin-left: 0;
  }

  .tooltip {
    display: flex !important;
  }
}

@media screen and (min-width: 1100px) {

  /* Desktop expanded */

  .list {
    align-self: center;
    width: 240px;
    font-size: 16px;
  }

  .listItemIcon {    
    display: flex;
    margin: 0;
    align-self: center;
    margin-right: 1.6rem;
    width: auto;
  }

  .panel {
    width: 260px;    
    align-items: inherit;
    padding: 2.2rem 2.2rem 0;
  }

  .logoFull {
    display: block;
  }

  .logoCompact {
    display: none;
  }

  .selected::after {
    left: -4.57rem;
  }

  .content {
    margin-left: 260px;
  }

  .header {
    display: flex;
    padding: 0 1.6rem;
  }

  .listItemTitle {
    display: flex;
  }
 
  .logoCompact {	
    display: full;	
  }	

  .selected::after {
    left: -3.8rem;
  }

  .list > * + * {
    margin-top: 0.8rem;
    font-size: 16px;
  }

  .tooltip {
    display: none !important;
  }
  
  .navItem {
    margin-left: 20px;
  }

  .listItem {
    margin-left: 0.5em;
    padding-left: 0.75em;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    height: 150px;
    align-items: center;
  }

  .createButton {
    width: 160px;
    font-size: 15px;
  }
  
  .createButton > * + * {
    margin-left: 0.25em;
  }
}
