.footer {
  display: flex;
  flex-direction: column;
  margin-top: var(--size-md);
  padding-top: var(--size-base);
  border-top: 1px solid var(--color-cool-grey-20);
}

.footer > * + * {
  margin-top: var(--size-base);
}

@media screen and (min-width: 700px) {
  .footer {
    flex-direction: row;
    margin-top: var(--size-xl);
  }

  .footer > * + * {
    margin-top: 0;
    margin-left: var(--size-base);
  }
}