.icon {
  font-size: 14rem;
  color: var(--accent-red);
}

.message {
  margin-bottom: 2%;
  font-size: var(--sizes-large);
}

.container {
  text-align: center;
  margin-top: 10%;
}

.title {
  font-size: var(--sizes-xxxlarge);
  font-family: var(--font-bold);
  color: var(--accent-red);
}
