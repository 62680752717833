.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .form > * + * {
    margin-top: 1.6rem;
  }
  
  .inputGroup {
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
  
  .inputGroupHeader {
    display: flex;
    justify-content: space-between;
  }
  
  @media screen and (min-width: 900px) {
    .form {
      align-self: stretch;
    }
  }