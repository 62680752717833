.list {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.4rem;
}

.item {
  display: flex;
  align-items: center;
  height: 4rem;
  color: var(--color-cool-grey-50);
  text-decoration: none;
  white-space: nowrap;
}

.item:hover {
  text-decoration: none;
}

.item:focus {
  outline: none;
  text-decoration: none;
}

.selected {
  position: relative;
  color: var(--color-cool-grey-70);
}

.selected::after {
  content: '';
  position: absolute;
  background-color: var(--default-blue);
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: 2px;
}

.list > * + * {
  margin-left: 3.2rem;
}
