.inputGroup {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 200px;
}

.searchBox {
  width: '200px';
  text-transform: capitalize;
}

.label {
  text-transform: capitalize;
}

.inputGroupHeader {
  display: flex;
  justify-content: space-between;
}
