.content {
  align-self: center;
  padding-bottom: 2.5rem;
  overflow: auto;
}

.date {
  width: fit-content;
  min-width: 140px;
}

.middle {
  text-align: center;
  padding-bottom: 2rem;
}

.fixedWidth {
  white-space: normal;
  max-width: 25%;
  flex-wrap: wrap;
}

.margin {
  margin: 1rem;
  width: 95%;
}

.header {
  font-family: "Proxima N W01 Smbd", "Proxima Nova Semibold", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.data, .header {
  padding: 2rem;
}

.row:not(:last-child) .data {
  border-bottom: 1px solid #dfdfdf;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.monospaced {
  font-family: monospace;
  font-size: 1.6rem;
}

.grow {
  width: 100%;
}

.table {
  padding: 2rem;
}

@media only screen and (min-width: 1000px) {
  .container {
    width: auto;
    margin: 0;
  }

  .data, .header {
    padding: 1.6rem;
  }
}
