.body {
  padding: 1rem 2rem;
}

.container {
  box-shadow: var(--shadow-small);
  background-color: var(--color-white);
}

.header {
  background-color: var(--color-grey-50);
  padding: 0.5em;
}

.title {
  color: var(--color-white);
  text-transform: uppercase;
  margin: 0;
}
