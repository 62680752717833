.page {
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  padding: 2.4rem;
  box-sizing: border-box;
}

.pageWide {
  max-width: 700px;
}

.page > * + * {
  margin-top: 3.2rem;
}

.logo {
  align-self: center;
}

.content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.content > * + * {
  margin-top: 2.5rem;
}

.spacerTop {
  flex: 1;
}

.spacerBottom {
  flex: 2;
}

@media screen and (min-width: 900px) {
  .page {
    padding: 4rem;
    align-items: flex-start;
  }

  .content {
    align-items: flex-start;
  }
}