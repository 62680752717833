.alert {
  padding: 0.8rem 1.6rem;
  background-color: var(--default-magenta);
  color: var(--color-magenta-70);
}

.alert ::first-letter {
  text-transform: capitalize;
}

.alert a, .alert a:hover {
  color: inherit;
  text-decoration: underline;
}

.success {
  background-color: var(--color-green-10);
  color: var(--color-green-70);
}
