:root {
  --stack-spacing: var(--size-base);
}

.stack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stack > * + * {
  margin-top: var(--stack-spacing);
}

.stackRow {
  flex-direction: row;
  align-items: center;
}

.stackRow > * + * {
  margin-top: 0;
  margin-left: var(--stack-spacing);
}

/* Spacing */

.spacingNone {
  --stack-spacing: 0;
}

.spacingSm {
  --stack-spacing: var(--size-sm);
}

.spacingMd > * + * {
  --stack-spacing: var(--size-md);
}

.spacingLg > * + * {
  --stack-spacing: var(--size-lg);
}

.spacingXl > * + * {
  --stack-spacing: var(--size-xl);
}

.spacing2Xl > * + * {
  --stack-spacing: var(--size-xxl);
}

/* Alignment */

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.alignStretch {
  align-items: stretch;
}
