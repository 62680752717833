.container {
  position: relative;
  padding: 1rem;
  margin-bottom: 2rem;
  width: fit-content;
}

.barcode {
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.addMargin{
  margin-top: 0.75em;
}
