.container {
  width: 35%;
  float: left;
  margin: 1rem;
  min-width: 275px;
}

.heading {
  font-size: 2rem;
  padding-bottom: 1.5rem;
}

.middle {
  text-align: center;
  padding-bottom: 2rem;
}

.scanSummaryIcon {
  font-size: 100px;
  color: black;
}

.content {
  padding-bottom: 0.5em;
}

.efficiency {
  display: flex;
  align-items: center;
  margin-bottom: 0.25em;
}
