.defaultColor {
  font-size: larger;
  color: red;
  margin-left: 0.25em;
}

.green {
  color: green;
}

.amber {
  color: orange;
}
