.table {
  margin-top: 1em;
}

.data, .header {
  padding: 2rem;
  white-space: nowrap;
}

.header {
  font-weight: bold;
}

.left {
  text-align: left;
}

.grow {
  width: 100%;
}

.table {
  padding: 2rem;
}

@media only screen and (min-width: 1000px) {
  .data, .header {
    padding: 1.6rem;
  }
}
