.container {
  width: 100vw;
  overflow-x: scroll;
  margin: 0 -2rem;
}

.OrdersList {
  width: 100%;
  border-collapse: collapse;
}

.header {
  font-family: "Proxima N W01 Smbd", "Proxima Nova Semibold", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.mainTable {
  padding: 0;
}

.data, .header {
  padding: 2rem;
  white-space: nowrap;
}

.row:not(:last-child) .data {
  border-bottom: 1px solid #dfdfdf;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.monospaced {
  font-family: monospace;
  font-size: 1.6rem;
}

.grow {
  width: 100%;
}

.table {
  padding: 2rem;
}

@media only screen and (min-width: 1000px) {
  .container {
    width: auto;
    margin: 0;
  }

  .data, .header {
    padding: 1.6rem;
  }
}
