/* Button styles */

:root {
  --button-text: var(--default-purple);
  --button-color: var(--color-white);
  --button-border: var(--default-purple);
  --button-hover: var(--color-blue-10);
}

.primary {
  --button-text: var(--color-white);
  --button-color: var(--default-purple);
  --button-border: var(--default-purple);
  --button-hover: var(--color-blue-50);
}

.primaryRollover {
  --button-text: var(--color-white);
  --button-color: var(--color-primary-rollover);
  --button-border: var(--color-primary-rollover);
  --button-hover: var(--color-blue-50);
}

.secondary {
  --button-text: var(--color-white);
  --button-color: var(--default-purple);
  --button-border: var(--default-purple);
  --button-hover: var(--color-blue-50);
}

.secondaryRollover {
  --button-text: var(--color-white);
  --button-color: var(--color-secondary-rollover);
  --button-border: var(--color-secondary-rollover);
  --button-hover: var(--color-blue-50);
}

.secondaryOutline {
  --button-text: var(--default-purple);
  --button-border: var(--default-purple);
  --button-color: var(--color-white);
  --button-hover: var(--color-blue-10);
}

.secondaryOutlineRollover {
  --button-text: var(--color-white);
  --button-border: var(--default-purple);
  --button-color: var(--default-purple);
  --button-hover: var(--color-blue-10);
}

.destructive {  
  --button-text: var(--color-magenta-60);
  --button-border: var(--color-magenta-60);
  --button-color: var(--color-white);
  --button-hover: var(--color-magenta-10);
}

.destructiveFill {
  --button-text: var(--color-white);
  --button-border: var(--color-magenta-60);
  --button-color: var(--color-magenta-60);
  --button-hover: var(--color-magenta-70);
}

.outline {
  --button-color: transparent;
}

.flat {
  --button-border: transparent;
  --button-color: transparent;
}

/* Structure */

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--size-base);
  font-weight: 400;
  appearance: none;
  border: 1px solid var(--button-border);
  background-color: var(--button-color);
  color: var(--button-text);
}

.base:hover {
  cursor: pointer;
  background-color: var(--button-hover);
}

.secondary:hover{
  border: 1px solid var(--button-hover);
}

.primary:hover {  
  border: 1px solid var(--button-hover);
}

.base:focus {
  border: 1px solid var(--button-border);
  outline: 2px solid var(--button-color);
}

.base:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.base::-moz-focus-inner {
  border: 0;
}

/* Sizes */

.base {
  height: var(--size-xl);
  padding: 0 var(--size-base);
}

.base > * + * {
  margin-left: var(--size-sm);
}

/* Disable sizes on mobile devices */
@media screen and (min-width: 700px) {
  .small {
    height: var(--size-lg);
    padding: 0 var(--size-sm);
    font-size: 1.4rem;
  }

  .small > * + * {
    margin-left: var(--size-xs);
  }

  .large {
    height: var(--size-2xl);
    padding: 0 var(--size-md);
    font-size: 1.9rem;
  }

  .large > * + * {
    margin-left: var(--size-base);
  }
}