:root {  
  --size-xxs: 0.4rem;
  --size-xs: 0.8rem;
  --size-sm: 1.2rem;
  --size-base: 1.6rem;
  --size-md: 2.4rem;
  --size-lg: 3.2rem;
  --size-xl: 4rem;
  --size-xxl: 4.5rem;

  --font-regular: objektiv-mk2,sans-serif;
  --font-bold: objektiv-mk2,sans-serif;
  --font-thin: objektiv-mk2,sans-serif;

  --sizes-xxxlarge: 4.74rem;
  --sizes-xxlarge: 3.55rem;
  --sizes-xlarge: 2.67rem;
  --sizes-large: 2rem;
  --sizes-medium: 1.8rem;
  --sizes-base: 1.5rem;
  --sizes-small: 1.125rem;
}
