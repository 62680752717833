.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: var(--size-base);
  padding-bottom: calc(var(--size-xxl) * 2);
  padding-left: var(--size-base);
  padding-right: var(--size-base);
  box-sizing: border-box;
}

.pageContainer {
  max-width: 1200px;
  margin: 0 auto;
}

.pageContainer > * + * {
  margin-top: var(--size-md);
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.content {
  flex: 1;
}

@media screen and (min-width: 700px) {
  .footerContainer {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .page {
    padding: var(--size-md);
  }

  .progress {
    position: relative;
    display: flex;
    margin: 0;
  }

  .footerVisible {
    display: block;
  }
}
