.base {
  appearance: none;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1.5rem;
  background-color: var(--color-white);
  padding: 0.5rem 2.4rem 0.5rem 0.5rem;
  border: 1px solid var(--color-cool-grey-30);
  outline: 0;
  height: 4rem;
  background-image: url('./icon-downcaret.gif');
  background-position: center right;
  background-repeat: no-repeat;
}

.base:focus {
  border-color: var(--default-blue);
  outline: 1px solid var(--default-blue);
}
