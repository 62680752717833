.fullPageCover {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.background {
  composes: fullPageCover;
  z-index: var(--background-ZIndex);
  opacity: 0.7;
  background-color:var(--color-black);
}

.container {
  composes: fullPageCover;
  z-index: var(--container-ZIndex);
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: auto;
}

.content {
  box-shadow: var(--shadow-large), var(--shadow-small);
  background-color:var(--color-white);
  padding: 1.25em;
}
