@value breakpoint-medium from '../../../components/styles/mediaQueries.css';

.content {
  align-self: center;
  padding-bottom: 2.4rem;
}

.search {
  display: flex;
  justify-content: space-between;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  max-width: 320px;
}

.searchBox {
  width: 310px !important;
}

.leftColumn {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  max-width: 420px;
}

.historyList {
  margin-top: 5rem;
}

.feedback {
  margin-top: 2rem;
}

@media breakpoint-medium {
  .leftColumn {
    flex-direction: column;
    width: auto;
  }

  .inputGroup {
    padding-bottom: 1em;
  }

  .button {
    width: fit-content;
  }
}
