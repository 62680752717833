.modalContainer {
  width: 500px;
}

.modalHeader {
  font-size: var(--size-lg);
  font-weight: bold;
  text-align: center;
}

.modalButtonContainer {
  display: flex;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
}

.modalButton {
  margin-right: 1em;
}

.modalMessage {
  margin-top: 0.5em;
}

.modalItalics {
  font-style: italic;
}
