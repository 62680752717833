.header {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--size-base);
  border-bottom: 1px solid var(--color-cool-grey-20);
}

.header > * + * {
  margin-top: var(--size-base);
}

.back {
  position: relative;
  left: calc(var(--size-base) * -1);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleContainer > * + * {
  margin-top: var(--size-sm);
}

.title {
  margin: 0;
  font-size: var(--size-lg);
  font-weight: bold;
}

.description {
  margin: 0;
  color: var(--color-grey-50);
}

.actionsContainer {
  display: flex;
  flex-direction: column;
}

.actionsContainer > * + * {
  margin-top: var(--size-sm);
}

@media screen and (min-width: 700px) {
  .titleContainer {
    flex-direction: row;
    align-items: center;
  }

  .titleContainer > * + * {
    margin-top: 0;
    margin-left: var(--size-base);
  }

  .actionsContainer {
    flex-direction: row;
  }

  .actionsContainer > * + * {
    margin-top: 0;
    margin-left: var(--size-base);
  }
}