.primaryStyle {
  border: 1px solid var(--color-grey-50);
  box-sizing: border-box;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  background-color: var(--color-white);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  outline: 0;
  width: 100%;
  height: 4rem;
}

.primaryStyle:focus {
  border: 1px solid var(--default-blue);
  outline: 1px solid var(--default-blue);
}

.upper {
  text-transform: uppercase;
}
