:root {
  --color-green-10: #D1FADD;
  --color-green-70: #1D7737;
  --color-orange-10: #FFDCB9;
  --color-orange-80: #8E4803;

  --color-alert-green: #3DBB61;
  --color-alert-orange: #FC7B1E;
  --color-alert-pink: #FB4B53;
  --color-alert-red: #DA1E28;
  --color-alert-yellow: #FAE34D;
  --color-alert-purple: #4352FF;

  --color-black: #000000;
  --color-white: #FFFFFF;

  --color-blue-10: #DCD1FD;
  --color-blue-20: #B09DF8;
  --color-blue-30: #866FF0;
  --color-blue-40: #624BE4;
  --color-blue-50: #4630D4;
  --color-blue-60: #3F17BD;
  --color-blue-70: #420FA0;
  --color-blue-80: #3F087D;
  --color-blue-90: #340356;
  --color-blue-100: #21002E;

  --color-cool-grey-10: #ECEDF3;
  --color-cool-grey-20: #D1D3DC;
  --color-cool-grey-30: #AFB2BE;
  --color-cool-grey-40: #9295A4;
  --color-cool-grey-50: #787C8C;
  --color-cool-grey-60: #5C606F;
  --color-cool-grey-70: #454856;
  --color-cool-grey-80: #2F323D;
  --color-cool-grey-90: #1B1D25;
  --color-cool-grey-100: #101217;

  --color-cyan-10: #E3F6FF;
  --color-cyan-20: #B3E6FF;
  --color-cyan-30: #6CCAFF;
  --color-cyan-40: #30B0FF;
  --color-cyan-50: #1191E6;
  --color-cyan-60: #0072C3;
  --color-cyan-70: #0058A1;
  --color-cyan-80: #003D73;
  --color-cyan-90: #002749;
  --color-cyan-100: #07192B; 

  --color-grey-5: #F9F9F9;
  --color-grey-10: #F3F3F3;
  --color-grey-20: #DCDCDC;
  --color-grey-30: #BEBEBE;
  --color-grey-40: #A4A4A4;
  --color-grey-50: #8C8C8C;
  --color-grey-60: #6F6F6F;
  --color-grey-70: #565656;
  --color-grey-80: #3D3D3D;
  --color-grey-90: #252525;
  --color-grey-100: #171717;

  --color-magenta-10: #FFF0F6;
  --color-magenta-20: #FFCFE1;
  --color-magenta-30: #FFA0C2;
  --color-magenta-40: #FA75A6;
  --color-magenta-50: #EE538B;
  --color-magenta-60: #D12765;
  --color-magenta-70: #A11950;
  --color-magenta-80: #760A3A;
  --color-magenta-90: #4F0027;
  --color-magenta-100: #2A0A16;

  --color-teal-10: #DBFBFB;
  --color-teal-20: #92EEEE;
  --color-teal-30: #20D5D2;
  --color-teal-40: #00BAB6;
  --color-teal-50: #009C98;
  --color-teal-60: #007D79;
  --color-teal-70: #006161;
  --color-teal-80: #004548;
  --color-teal-90: #002B30;
  --color-teal-100: #081A1C;

  --shadow-small: 0px 10px 10px rgba(0, 0, 0, 0.03);
  --shadow-large:0px 20px 20px rgba(0, 0, 0, 0.05);

  --default-purple: var(--color-alert-purple);
  --default-blue: var(--color-blue-50);
  --default-teal: var(--color-teal-20);
  --default-grey: var(--color-grey-20);
  --default-magenta: var(--color-magenta-20);
  --default-cyan: var(--color-cyan-50);
  --default-cool-grey: var(--color-cool-grey-40);

  --color-primary-rollover: #4122FF;
  --color-secondary-rollover: #1B2CF2;

  --alert: var(--color-alert-pink);

  --greyscale-dark: var(--color-grey-90);
  --greyscale-mid:   var(--color-grey-70);
  --greyscale-base: var(--color-grey-50);
  --greyscale-light: var(--color-grey-20);
  --greyscale-lightest: var(--color-grey-10);

  --text-body: var(--color-grey-80);
  --text-heading: var(--color-grey-100);
  --text-light: var(--color-grey-50);
  --text-lightest: var(--color-grey-40);

  --accent-blue: var(--color-blue-50);
  --accent-blue-dark: var(--color-blue-60);
  --accent-red: var(--color-alert-pink);
}
