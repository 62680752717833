.panel {
  margin-top: 2em;
}

.spacer {
  margin-bottom: 1.5em !important;
}

.header {
  font-family: "Proxima N W01 Smbd", "Proxima Nova Semibold", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.data, .header {
  padding: 2rem;
  white-space: nowrap;
  text-align: left;
}

.alert {
  background-color: var(--default-magenta);
  padding: 0.8rem 1.6rem;
  color: var(--color-magenta-70);
  text-align: center !important;
}

.row:not(:last-child) .data {
  border-bottom: 1px solid #dfdfdf;
}

.itemTable:first-of-type {
  margin-top: 2em;
}

.itemTable:not(:first-of-type) {
  margin-top: 1em;
}

.orderDivider {
  margin-top: 1em;
  border-top: 2px solid var(--color-grey-30);
  padding-bottom: 1em;
}

.heading {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.content:not(:last-child) {
  margin-right: 2em;
}

.heading {
  font-weight: bold;
}

.monospaced {
  font-family: monospace;
  font-size: 1.6rem;
}

.fixedWidth {
  white-space: normal;
  max-width: 50%
}

.grow {
  width: 30%;
  max-width: 100%;
}

.name {
  white-space: normal;
  width: 20%;
  max-width: 50%;
}

.table {
  padding: 2rem;
}

@media only screen and (min-width: 1000px) {
  .data, .header {
    padding: 1.6rem;
  }


  .grow {
    width: 50%;
    max-width: 100%;
  }
}
