@value breakpoint-tablet-small from '../../components/styles/mediaQueries.css';
@value breakpoint-mobile from '../../components/styles/mediaQueries.css';

.content {
  align-self: center;
  padding-bottom: 1rem;
  overflow: auto;
}

.heading {
  font-size: 2rem;
}

.sessions {
  padding-bottom: 2.5rem;
  float: left;
  margin: 1rem;
  width: 200px;
}

.panelContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.cloud {
  color: white;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
  font-size: 60px;
}

.icon {
  font-size: 60px;
  color: black;
}

.middle {
  text-align: center;
  padding-bottom: 2rem;
}

.data, .header {
  padding: 2rem;
  white-space: nowrap;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

@media breakpoint-tablet-small {
  .container {
    width: auto;
    margin: 0;
  }

  .data, .header {
    padding: 1.6rem;
  }

  .panelContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media breakpoint-mobile {
  .panelContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
