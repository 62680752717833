@value breakpoint-mobile from '../../components/styles/mediaQueries.css';

.container {
  padding: 5rem 0;
  text-align: center;
}

.logo {
  align-self: center;
}

.titleContainer {
  align-self: center;
  padding-top: 6rem;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scanForm {
  width: 380px;
}

.userOverview {
  max-width: calc(100% - 380px);
  margin-left: 2em;
}

.user {
  font-weight: bold;
  margin-bottom: 1em;
}

.endSessionButton {
  margin-top: 2em;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  max-width: 380px;
}

.form > * + * {
  margin-top: 1.6rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.checkboxGroup {
  display: flex;
  align-self: stretch;
}

.hideOption {
  display: none;
}

.checkbox {
  margin-left: 1em;
  height: 15px;
  width: 15px;
  align-self: center;
}

.inputGroupHeader {
  display: flex;
  justify-content: space-between;
}

.note {
  height: 8rem;
}

.feedback {
  margin-bottom: 1em;
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 2.4rem;
  box-sizing: border-box;
}

.noteStyle {
  height: 8rem;
}

.noteFeedback {
  margin: 0;
}

.noteFeedback:not(:last-child) {
  margin-bottom: 0.5em;
}

.total input {
  width: 25%;
}

@media screen and (min-width: 900px) {
  .form {
    align-self: stretch;
  }
  
  .page {
    padding: 4rem;
    align-items: flex-start;
  }
}

@media breakpoint-mobile {
  .contentContainer {
    flex-direction: column;
    justify-content: unset;
  }

  .userOverview {
    margin-left: 0em;
    margin-top: 2em;
    max-width: 100%;
  }
}
