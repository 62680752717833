@value breakpoint-medium from '../../../components/styles/mediaQueries.css';

.content {
  align-self: center;
  padding-bottom: 2.4rem;
}

.inputGroupHeader {
  display: flex;
  justify-content: space-between;
}

.search {
  display: flex;
  justify-content: space-between;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 200px;
}

.leftColumn {
  float: left;
  padding-right: 10rem;
} 

.sessionList {
  margin-top: 2rem;
  padding-bottom: 2rem;
  max-width: 350px;
  min-width: 200px;
}

.addPadding {
  padding-bottom: 3rem;
}

.addPaddingTop {
  padding-top: 3rem;
}

.addSmallPadding {
  padding-top: 1rem;
}

.typePadding {  
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.searchBox {
  width: 200px;
}

.dateTime {
  width: 220px !important;
}

.relatedContainer {
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-width: 200px;
}

.relatedSessions {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0;
}

.relatedSessionColumn {
  float: left;
  padding-right: 1rem;
  width: 200px;
}

.addSessionButton {
  display: flex;
  flex-direction: column;
  width: 155px;
  align-self: end;
}

.addEquipment {
  width: 150px;
}

.searchSession {
  width: 200px;
  float: left;
  padding-right: 5rem;
}

.checkboxGroup {
  display: flex;
  align-self: stretch;
}

.completed {
  margin-left: 1em;
  height: 15px;
  width: 15px;
  align-self: center;
}

.text {
  width: 100%;
  margin-top: 0.75em;
  margin-bottom: 1em;
}

.relatedOrderItemHeader {
  margin-bottom: 0.75em;
}

@media breakpoint-medium {
  .relatedContainer {
    flex-direction: column;
  }

  .relatedSessionColumn {
    padding-bottom: 1em;
  }
}
