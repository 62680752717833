p {
  margin-bottom: 0;
}

.heading {
  margin-bottom: 0.5em;
}

.subTitle {
  margin-top: 0.5em
}
