/*
--------------------------------------------------------------------------------
Reset and GLOBAL styles.
--------------------------------------------------------------------------------
*/

/*RESETS*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
  display: block;
}

body {
  line-height: 1;
}

ol,ul {
  list-style: none;
}

blockquote,q {
  quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  color: var(--color-grey-80);
  font-size: 62.5%;
}

/*STANDARD FONT/TYPOGRAPHY*/
body,button,input {
  font-family: objektiv-mk2,sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--color-grey-80);
}

/*GLOBAL LAYOUT*/
body {
  background-color: var(--color-white);
}

html, body, #root {
  height: 100%;
}

/*INLINE STYLES*/
a {
  text-decoration: none;
  color: var(--color-blue-50);
}

a:hover {
  text-decoration: none;
}

strong,b {
  font-family: objektiv-mk2,sans-serif;
  font-weight: 500;
}

em {
  font-style: italic
}

code,pre {
  font-family: Menlo,Monaco,"Courier New",monospace;
  font-size: 0.85em;
}

pre {
  background: var(--color-white);
  margin: 25px 0;
  padding: 1em;
  max-width: 100%;
  display: block;
  overflow: auto;
}

h1,h2,h3,h4,h5,h6 {
  color: var(--color-grey-80);
  margin: 0.5em 0;
  line-height: 1.2;
  font-family: objektiv-mk2,sans-serif;
}

h1 {
  font-size: 4.74rem;
}

h2 {
  font-size: 3.55rem;
}

h3 {
  font-size: 2.67rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-family: objektiv-mk2,sans-serif;
}

p {
  margin-bottom: 0.75em
}

/*TABLES*/
.table {
  width: 100%;
}

.table caption {
  font-family: objektiv-mk2,sans-serif;
  text-align: left;
  padding-bottom: 0.5em;
}

.table th {
  display: none;
  font-family: objektiv-mk2,sans-serif;
  font-size: 1.125rem;
  text-align: left;
  white-space: nowrap;
}

.table td {
  display: block;
  width: 100%;
  padding: 0;
}

.table td:last-child {
  padding-bottom: 1em;
}

@media only screen and (min-width: 1000px) {
  .table {
    margin: 25px 0;
  }

  .table caption {
    padding: 0 1em 0.5em 1em;
  }

  .table th {
    display: table-cell;
    padding: 0.5em 1em 1em 1em;
  }

  .table td {
    display: table-cell;
    padding: 0.5em 1em;
    width: auto;
  }

  .table tbody tr:first-child td {
    border-top: 1px solid #DBDBD9;
    padding-top: 1em;
  }

  .table td:last-child {
    padding-bottom: 0;
  }

  .table tbody tr:last-child td {
    border-bottom: 1px solid #DBDBD9;
    padding-bottom: 1.5em;
  }
}

/*
--------------------------------------------------------------------------------
TODO: Migrate everything below here to individual component styles
--------------------------------------------------------------------------------
*/

mark {
    background: var(--color-blue-50);
    color: var(--color-white);
    padding: 0 0.2em
}

.bulleted {
    list-style: disc outside;
    margin: 1em 0 1em 2em
}

dt {
    float: left;
    width: 5em;
    margin-right: 2em
}

.u-scale {
    width: 100%;
    max-width: 100%
}

.u-scale-down {
    max-width: 100%
}

.u-bordered-img {
    border: 5px solid var(--color-white);
    outline: 1px solid #EAEAEA;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.u-hidden {
    display: none
}

.u-nowrap {
    white-space: nowrap
}

.toggle-next {
    width: 75px
}

.toggle-next .inactive:before {
    content: '+ '
}

.toggle-next .active {
    display: none
}

.toggle-next .active:before {
    content: '- '
}

.toggle-next--active .inactive {
    display: none
}

.toggle-next--active .active {
    display: inline
}

@media only screen and (min-width: 1000px) {

    label .optional {
        display: block
    }

}

.sg-header {
    max-width: 1400px;
    margin: 0 auto;
    background: var(--greyscale-mid);
    height: 80px
}

.sg-header::after {
    clear: both;
    content: "";
    display: block
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .sg-header {
        max-width:calc(100% - 40px)
    }
}

.sg-header__link {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px
}

.sg-header__logo {
    padding: 25px 0
}

.sg-header__title {
    font-family: Arial;
    font-size: 25px;
    margin: 0;
    float: right;
    color: var(--color-white);
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
    text-align: right;
    line-height: 80px
}

.sg-section {
    max-width: 1400px;
    margin: 0 auto;
    padding: 50px 0;
    border-bottom: 1px solid #DBDBD9
}

.sg-section::after {
    clear: both;
    content: "";
    display: block
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .sg-section {
        max-width:calc(100% - 40px)
    }
}

.sg-title {
    font-family: Arial;
    font-size: 25px;
    margin-top: 0;
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px
}

.sg-subtitle {
    font-family: Arial;
    margin-top: 50px;
    font-size: 20px;
    clear: left
}

.sg-subtitle:first-child {
    margin-top: 0
}

.sg-content {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px
}

.sg-content code {
    font-size: 1.4rem
}

.sg-small-text {
    font-size: 1.4rem
}

.sg-swatch {
    float: left;
    width: 200px;
    height: 120px;
    margin: 0 25px 25px 0
}

.sg-swatch__block {
    display: block;
    width: 200px;
    height: 80px
}

.sg-swatch__caption {
    font-size: 1.4rem
}

.sg-detail {
    display: block;
    font-family: objektiv-mk2,sans-serif;
    font-size: 1.4rem;
}

.sg-logo-size {
    display: inline;
    padding-right: 1em
}

.sg-white-logo {
    background: var(--greyscale-mid);
    margin-top: 50px;
    padding: 15px 25px
}

.sg-content label {
    width: auto
}

.sg-button {
    margin-bottom: 25px
}

.sg-footer {
    max-width: 1400px;
    margin: 0 auto
}

.sg-footer::after {
    clear: both;
    content: "";
    display: block
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .sg-footer {
        max-width:calc(100% - 40px)
    }
}

.sg-footer-text {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding: 25px 0;
    margin-bottom: 0;
    font-size: 1.4rem
}

@media only screen and (min-width: 750px) {
    .sg-title {
        width:calc(25% - 25px);
        float: left;
        margin-left: 20px
    }

    .sg-content {
        width: calc(75% - 35px);
        float: left;
        margin-left: 20px
    }
}


.form-signin .form-signin-heading, .form-signin .checkbox
{
    margin-bottom: 10px;
}

.form-signin .form-control:focus
{
    z-index: 2;
}
.form-signin input[type="text"]
{
    margin-bottom: -1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.form-signin input[type="password"]
{
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.login-title
{
    color: var(--greyscale-mid);
    font-size: 18px;
    font-weight: 400;
    display: block;
    text-align: right;
}

.need-help
{
    margin-top: 10px;
}
.new-account
{
    display: block;
    margin-top: 10px;
}

.loading {
  cursor: wait !important;
}

.dark-background {
  background-color: var(--color-grey-80);
}

.dropdown-hover {
  background-color: var(--color-white);
}

.dropdown-hover:hover {
  background-color: var(--default-grey);
}

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}
